let showCheckboxTimeout;

function hideManualAddressCheckbox() {
    $(`[name="show_address_fields"]`).closest('div.hs-form-field').hide();
}

function showManualAddressCheckbox() {
    $(`[name="show_address_fields"]`).closest('div.hs-form-field').show();
}

function initializeAutocomplete(type = 'address') {
    const addressField = document.querySelector('[name="complete_address"]');

    addAutocomplete(addressField, type);
}

function addAutocomplete(inputField, type) {
    const options = {
        types: [type],
        componentRestrictions: { country: 'au' }
    };

    if (inputField) {
        const autocomplete = new google.maps.places.Autocomplete(inputField, options);

        google.maps.event.addListener(autocomplete, "place_changed", function () {
            prepareSubmission(autocomplete);
        });

        // Don't allow submission via enter (as it won't trigger place_changed)
        inputField.addEventListener("keydown", function (event) {
            if (event.keyCode === 13) {
                event.preventDefault();
            }
        });
    }
}

function prepareSubmission(autocomplete) {
    const transalation = {
        'country': 'country',
        'locality': 'city',
        'postal_town': 'city',
        'postal_code': 'zip',
        'administrative_area_level_1': 'state',
    };

    const fields = Object.keys(transalation);
    const place = autocomplete.getPlace();
    
    if (!place.address_components) {
        streetAddressField.val('').change();
        showHideErrorMsg('complete-address-select', true);
        return;
    } else {
        showHideErrorMsg('complete-address-select', false);
    }

    for (let i = 0; i < place.address_components.length; i++) {
        const addressTypes = place.address_components[i].types;
        const match = addressTypes.filter(v => fields.includes(v));

        if (match[0] && match[0] === 'country') {
            $(`[name=${transalation[match[0]]}]`).val(place.address_components[i].long_name).change();
        } else if (match[0]) {
            $(`[name=${transalation[match[0]]}]`).val(place.address_components[i].short_name).change();
        }
    }
    
    const streetAddressField = $(`[name="address"]`);
    if (streetAddressField.length > 0) {
        const placeRoute = place.address_components.filter(e => e.types.includes('route'))[0];

        if (placeRoute) {
            route = placeRoute.short_name;
        }

        streetAddressField.val(route).change();
    }

    const streetNumberField = $(`[name="street_number"]`);
    if (streetNumberField.length > 0) {
        const placeStreetNum = place.address_components.filter(e => e.types.includes('street_number'))[0];

        let streetNum = "";
        if (placeStreetNum) {
            showHideErrorMsg('complete-address-error-msg', false);
            streetNum = placeStreetNum.short_name;
        } else {
            showHideErrorMsg('complete-address-error-msg', true);
        }

        streetNumberField.val(streetNum).change();
    }

}

function showHideErrorMsg(id, show = true) {
    const errorMsgEl = document.getElementById(id);

    if (!errorMsgEl) return;

    if (show) {
        errorMsgEl.removeAttribute('style');   
    } else {
        errorMsgEl.setAttribute('style', 'display: none');
    }
}